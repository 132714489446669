<template lang="pug">
  .hcb-services-offered.container(:class="{'extra-padding': returnSlides && returnSlides.length <= 4}")
    .row
      p.brand-color-1.title.mb-10.xs-f-xxxlarge.f-secondary.max-at-tweak.upper(v-if="cmsSettings") {{ cmsSettings.title }}
      .services-list.xs-12(v-if="returnSlides")
        ServiceBox(
          v-for="(service, index) in returnSlides"
          ref="serviceBoxes"
          :key="index"
          :service="service"
          :selected="selectedService && service._id === selectedService._id"
          @service-selected="() => onServiceSelected(service.code)"
          @open-service-quick-view-modal="() => openQuickViewModal(service)"
          :show-pricing="false"
          :classes="serviceClasses"
        )

    a.mr-btn.main-btn.xs-f-xsmall.max-at-tweak(href='/colorbar/locations' :class='mobileOrTablet ? "btn-block" : "btn-fixed-width"') {{cmsSettings.cta.text}}
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex';
  import ImgBox from '@components/imgBox';
  import ServiceBox from '@components/HairColorBarBooking/ServiceBox';

  export default {
    name: "HcbServicesOffered",
    components: {
      ImgBox,
      ServiceBox
    },

    inject: ['getObjProperty'],

    props: {
      cmsSettings: {
        type: Object,
        default: () => ({})
      },
    },

    async mounted() {
      let locationCode = this.getObjProperty(this, 'cdata.locationContext.closestColorbar');

      if (locationCode) {
        await this.getLocation(locationCode);
      }
      this.adjustServiceHeight();
    },

    computed: {
      ...mapState('customer', [
        'cdata',
      ]),

      ...mapState('global', ['windowData']),

      ...mapState('hairColorBarBooking', [
        'location',
        'selectedService'
      ]),

      ...mapGetters('hairColorBarBooking', [
        'servicesWithStandIns'
      ]),

      isMemberNonMemberExperiment() {
        return this.experiments['membervsnonmemberpricebookingflow'] == 'B';
      },

      serviceLimit() {
        return this.cmsSettings?.serviceLimit;
      },

      formatCmsSettings() {
        let slides = [];
        if (this.cmsSettings.slides && this.cmsSettings.slides.length > 0) {
          this.cmsSettings.slides.forEach((slide) => {
            slides.push({
              imageId: slide.image._id,
              displayName: slide.title,
              description: slide.description,
              code: slide.code,
            });
          });
        }
        return slides;
      },

      returnSlides() {
        if (this.servicesWithStandIns && this.servicesWithStandIns.length > 0) {
          if (this.serviceLimit && this.servicesWithStandIns.length > this.serviceLimit) {
            return this.servicesWithStandIns.slice(0, this.serviceLimit);
          }
          return this.servicesWithStandIns;
        }
        return this.formatCmsSettings;
      },

      serviceClasses() {
        if (this.returnSlides.length <= 4) {
          return ['xs-12', 'md-6', 'd-flex'];
        } else {
          return ['xs-12', 'md-6', 'lg-4', 'd-flex'];
        }
      },

      mobileOrTablet() {
        return this.windowData.breakpoint === 'mobile' || this.windowData.breakpoint === 'tablet';
      }
    },
    methods: {
      ...mapActions('hairColorBarBooking', [
        'getLocation'
      ]),

      getMinServiceHeight(serviceBoxes = []) {
        let height = 0;

        serviceBoxes.forEach(s => {
          let sHeight = s.$refs.firstRow.clientHeight;
          if (s.$refs.secondRow) {
            sHeight += s.$refs.secondRow.clientHeight;
          }

          if (sHeight > height) {
            height = sHeight;
          }
        });

        return height;
      },

      adjustServiceHeight() {
        const serviceBoxes = this.$refs.serviceBoxes || [];
        let height = this.getMinServiceHeight(serviceBoxes);
        serviceBoxes.forEach(s => {
          s.$refs.serviceInnerWrap.style.minHeight = `${height}px`;
        });
      },

      onServiceSelected(code) {
        let location = '/colorbar/locations';
        if (!code) {
          return window.location = location;
        }
        this.$cookies.set('selected_service', code);
        return window.location = location;
      }
    }
  };
</script>
<style scoped lang="stylus">
  .hcb-services-offered
    position relative
    margin 2em auto
    padding 1em
    background-color white
    max-width 1400px

    .services-list
      display flex
      flex-grow 3
      flex-wrap wrap
      justify-content center

  .no-location
    .slides
      text-align center
      display inline-flex
      flex-direction row
      justify-content center
      flex-wrap wrap

      .slide
        border 2px solid color-fog-light
        background-color white
        border-radius 0.5em
        padding 1em 0.5em
        margin 1em
        box-shadow 0 0.25em 0.5em color-fog-light

        .slide-text
        .slide-img
          display flex
          flex-direction column
          justify-content left
          height 100%

    .main-btn
      display flex
      justify-content center
      align-items center
      margin 1em auto

  @media mq-tablet-less
    .hcb-services-offered
      .no-location
        .slides
          display inline-flex
          flex-direction column

          .slide
            margin 1em 0

  @media mq-desktop-plus
    .hcb-services-offered
      &.extra-padding
        padding-left 10%
        padding-right 10%

</style>
